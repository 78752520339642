import { number } from "yup"
import { capitalize } from "../../utils/StringUtils"

export type ReportRequestModule = "CALLLOGS" | "CALLSTATS1" | "CALLSTATS2" | "CALLSTATS3" | string
export enum ReportRequestModuleEnum {
  CALLLOGS = "CALLLOGS",
  CALLSTATS1 = "CALLSTATS1",
  CALLSTATS2 = "CALLSTATS2",
  CALLSTATS3 = "CALLSTATS3",
  SURVEY_COC = "SURVEY_COC",
}

export const getReportRequestTitleByModule = (module: ReportRequestModule): string => {
  switch (module) {
    case ReportRequestModuleEnum.CALLLOGS:
      return "Call Logs"
    case ReportRequestModuleEnum.CALLSTATS1:
      return "Call Stats 1"
    case ReportRequestModuleEnum.CALLSTATS2:
      return "Call Stats 2"
    case ReportRequestModuleEnum.CALLSTATS3:
      return "Call Stats 3"
    case ReportRequestModuleEnum.SURVEY_COC:
      return "Change of Condition"
    default:
      return "Unknown"
  }
}

export const getReportRequestFilterParams = (filter: ReportRequestFilters | undefined): string[] => {
  if (!filter || !filter.params) {
    return []
  }
  const keys = Object.keys(filter.params)
  const params: string[] = []
  for (const key of keys) {
    if (!key.toLowerCase().includes("label")) {
      continue
    }
    const paramName = key.split("Label")
    params.push(`${capitalize(paramName[0])}: ${filter.params[key]}`)
  }
  return params
}

export enum ReportRequestStatus {
  CREATED = "created",
  IN_PROGRESS = "in-progress",
  CANCELLED = "cancelled",
  COMPLETED = "completed",
}

export interface ReportRequestFilters {
  startDate: Date | number | string
  endDate: Date | number | string
  fields: string[]
  params: {
    // @ts-ignore
    [index: string | symbol]: string
  }
}

export interface ReportRequestDTO {
  id: string
  organizationId: string
  requestedById: string
  downloadedById: string | null
  module: ReportRequestModule
  filters: ReportRequestFilters
  status?: ReportRequestStatus
  createdAt: Date | null
  updatedAt: Date
  processedAt: Date | null
  downloadedAt: Date | null
}

export interface ReportRequestPaginatedResponse {
  rows: ReportRequestDTO[]
  count: number
  pager: {
    page: number
    itemsPerPage: number
    totalItems: number
    totalPages: number
  }
}

export interface ReportRequestResponse {
  status: boolean
  data: ReportRequestPaginatedResponse
}

export interface ReportRequestUrl {
  url: string
}

export interface ReportRequestDownloadResponse {
  data: ReportRequestUrl
}
